<template>
  <v-btn 
    :block="block"
    :color="color || 'primary'"
    :disabled="disabled"
    :height="height || 45"
    :loading="loading"
    :outlined="outlined"
    :plain="plain"
    :rounded="rounded"
    :text="text"
    :width="width || 175"
    :max-width="maxWidth || '100%'"
    :class="this.class"

    @click="$emit('click')"
  >
    <slot />
    <v-progress-circular
      v-if="loading"
      indeterminate
      :color="color"
    ></v-progress-circular>
  </v-btn>
</template>
<script>
export default {
  name: 'Button',
  data: () => ({
    class: ['']
  }),
  props: {
    block: Boolean,
    classes: Array,
    color: String,
    disabled: Boolean,
    height: Number,
    loading: Boolean,
    maxWidth: String,
    outlined: Boolean,
    plain: Boolean,
    rounded: Boolean,
    text: Boolean,
    width: Number,
  },
}
</script> 
<style lang="scss" scoped>
  @import '@/assets/scss/_classes.scss';

  .v-btn { 
    border: 2px solid;
    box-shadow: 1px 1px 12px #0000005e;
    color: white;
    text-shadow: 1px 1px 2px black; 
    text-transform: none;
    font-size: 20px; 
    font-family: 'Pacifico', cursive;
  }

  .theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
    background: #9f9d9d  !important;
    border: none;
    text-shadow: none;
  }


</style>